import { css } from 'styled-components';

const theme = {
  font: {
    family: 'trade-gothic-next, sans-serif',
    boldFamily: 'futura-pt-bold, sans-serif'
  },
  lightOrange: '#E46B00',
  darkOrange: '#B32C00',
  grey: '#8E8E93',
  blue: '#168080',
  dark: '#293639',
  darkTeal: '#114949',
  light: '#F0F6F8',
  lightGrey: '#979797',
  shadow: 'rgba(0,0,0,.5) 0 1px 3px',
  grid: true,
  headerHeight: 70,
  sizes: {
    content: 700,
    break: 768,
    large: 1024
  }
};


const media = Object.keys(theme.sizes).reduce((acc, label) => {
  acc[label] = (...args) => css`
    @media (min-width: ${theme.sizes[label]}px) {
      ${css(...args)}
    }
  `;
  return acc;
}, {});

const contentWidth = css`
  width: ${theme.sizes.content}px;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
`;

const gradient = css`
  background: linear-gradient(${theme.lightOrange}, ${theme.darkOrange});
`;

export { media, contentWidth, gradient };
export default theme;