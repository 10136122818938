import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Image from 'gatsby-image/withIEPolyfill';
import styled from 'styled-components';

import media from '../media';

const Footer = () => {
  const {
    sanitySiteSettings: {
      footerOptions: { footerLogos }
    }
  } = useStaticQuery(graphql`
    query {
      sanitySiteSettings {
        id
        footerOptions {
          footerLogos {
            _key
            alt
            link
            asset {
              fluid(maxWidth: 500) {
                ...GatsbySanityImageFluid
              }
            }
          }
        }
      }
    }
  `);

  return (
    <StyledFooter className="footer">
      {footerLogos.map(({ alt, link, asset, _key }) => {
        if (link) {
          return (
            <a
              key={_key}
              href={link}
              target="_blank"
              rel="noopener noreferrer"
              className="footer__logo-container"
            >
              <Image
                className="footer__logo"
                fluid={asset.fluid}
                alt={alt}
                objectFit="contain"
              />
            </a>
          );
        }
        return (
          <div key={_key} className="footer__logo-container">
            <Image
              className="footer__logo"
              fluid={asset.fluid}
              alt={alt}
              objectFit="contain"
            />
          </div>
        );
      })}
    </StyledFooter>
  );
};

const StyledFooter = styled.footer`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
  .footer {
    &__logo-container {
      width: 350px;
      max-width: 100%;
      margin: 2rem 0;
    }
    &__logo {
      max-height: 100px;
    }
  }
  ${media.break`
    flex-direction: row;
    justify-content: space-around;
    height: 200px;
    .footer {
      &__logo-container {
        width: auto;
        flex: 1 1 auto;
        min-height: 30px;
        margin: 0 1rem;
      }
    }
  `}
`;

export default Footer;