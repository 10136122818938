import React, { useContext } from 'react';
import styled from 'styled-components';
import { useTransition, animated } from 'react-spring';

import media from '../media';
import theme from '../theme';
import SiteContext from '../SiteContext';
import SearchForm from '../Search/SearchForm';
import Menu from '../Menu/Menu';

const Nav = ({ menu }) => {

  const { menuOpen, viewport, ready } = useContext(SiteContext);

  const transitionProps = ready
    ? viewport.width >= theme.sizes.break
      ? {
          from: {},
          enter: {},
          leave: {}
        }
      : {
          from: {
            transform: 'translateY(-100%)',
            display: 'flex',
            zIndex: 0
          },
          enter: {
            transform: 'translateY(0)'
          },
          leave: {
            transform: 'translateY(-100%)'
          }
        }
    : {
        from: {},
        enter: {},
        leave: {}
      };

  const transition = useTransition(menuOpen, null, transitionProps);

  return transition.map(({ item, key, props }) => (item || viewport.width > theme.sizes.break) && ready &&
    <NavContainer key={key} className="header-nav-container" viewport={viewport} style={props}>
      <StyledNav className="header-nav">
        <Menu menu={menu} />
      </StyledNav>
      <SearchForm />
    </NavContainer>
  );
};

const NavContainer = styled(animated.div)`
  display: none;
  ${media.break`
    display: flex;
  `}
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  height: ${({ viewport, theme }) => viewport.height - theme.headerHeight}px;
  position: absolute;
  top: ${({ theme }) => theme.headerHeight}px;
  left: 0;
  background: linear-gradient(
    ${({ theme }) => `${theme.lightOrange}, ${theme.darkOrange}`}
  );

  ${media.break`
    position: static;
    height: 100%;
    width: auto;
    flex-direction: row;
    background: transparent;
  `}
`;

const StyledNav = styled.nav`
  flex: 1 1;
  ${media.break`
    height: 100%;
  `}
  .header-nav__menu {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 100%;
    flex-direction: column;
    ${media.break`
      flex-direction: row;
    `}
  }
  .menu-item {
  }
  a {
    color: white;
    text-decoration: none;
    font-family: ${({ theme }) => theme.font.boldFamily};
    font-weight: 700;
    font-size: 2rem;
  }
`;

export default Nav;