import React from 'react';
import styled from 'styled-components';
import { animated } from 'react-spring';
import BlockContent from '@sanity/block-content-to-react';
import classNames from 'classnames';
import InstagramEmbed from '@aarnila/react-instagram-embed';
import ReactPlayer from 'react-player';

import theme, { contentWidth } from './theme';

const Content = React.forwardRef(({ children, styles, className }, ref) => {
  const serializers = {
    types: {
      instagramPost: ({ node }) => {
        const { url } = node;
        return (
          <InstagramEmbed
            url={url}
            accessToken="4696966563678545|90cf1e92d6fcf9847cf8e6423f37bca3"
            maxWidth={theme.sizes.content}
            containerTagName="div"
            injectScript
          />
        );
      },
      video: ({ node }) => {
        const { url } = node;
        return (
          <VideoContainer className="video-container">
            <ReactPlayer className="content-video" url={url} controls />
          </VideoContainer>
        );
      },
    },
  };
  return (
    <ContentWrapper
      ref={ref}
      className={classNames('content-wrapper', className)}
      style={styles}
    >
      <BlockContent blocks={children} serializers={serializers} />
    </ContentWrapper>
  );
});

const ContentWrapper = styled(animated.div)`
  ${contentWidth}
  margin-top: 5rem;
  margin-bottom: 5rem;
  &.resource__content {
    color: ${({ theme }) => theme.dark};
    font-family: ${({ theme }) => theme.font.family};
    font-size: 1.6rem;
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
`;

const VideoContainer = styled.div`
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  padding-top: 25px;
  height: 0;
  .content-video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100% !important;
    height: 100% !important;
  }
`;
export default Content;
