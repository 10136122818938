import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';

import Logo from '../../images/your-journey-home_sm-wh.svg';

const NavLogo = () => (
  <LogoContainer className="logo">
    <Link to="/" className="logo__link">
      <img className="logo__logo" src={Logo} alt="Your Journey Home" />
    </Link>
  </LogoContainer>
);

const LogoContainer = styled.div`
  height: 100%;
  z-index: 1;
  position: relative;
  img {
    height: 100%;
  }
`;

export default NavLogo;