import React from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';
import Image from 'gatsby-image/withIEPolyfill';
import styled from 'styled-components';

import { gradient, media } from './theme';

const ResourceCatsList = () => {

  const catRes = useStaticQuery(graphql`{
    allSanityResourceCategory {
      edges {
        node {
          id
          icon {
            alt
            asset {
              fluid(maxWidth: 250) {
                ...GatsbySanityImageFluid
              }
            }
          }
          title
          slug {
            current
          }
        }
      }
    }
  }`);


  return (
    <StyledResourceCatsBlock className="resource-cat-list">
      <h2 className="resource-cat-list__heading">Get Resources</h2>
      <ul className="resource-cat-list__list">
        {catRes.allSanityResourceCategory.edges.map(
          ({ node: { slug, title, id, icon } }) => (
            <li className="resource-cat-list__item" key={id}>
              <Link
                className="resource-cat-list__link"
                key={id}
                to={`/${slug.current}`}
              >
                <Image
                  objectFit="contain"
                  className="resource-cat-list__icon"
                  fluid={icon.asset.fluid}
                  alt={icon.alt}
                />
                <h3 className="resource-cat-list__title">{title}</h3>
              </Link>
            </li>
          )
        )}
      </ul>
    </StyledResourceCatsBlock>
  );
};

const StyledResourceCatsBlock = styled.div`
  ${gradient}
  color: white;
  padding: 2rem;

  a {
    color: white;
    text-decoration: none;
  }
  .resource-cat-list {
    &__heading {
      text-align: center;
      margin-bottom: 5rem;
    }
    &__list {
      list-style: none;
      padding: 0;
      margin: 0;
      ${media.break`
        display: flex;
        justify-content: center;
      `}
    }
    &__item {
      margin-bottom: 5rem;
      ${media.break`
        margin: 0 2rem;
      `}
    }
    &__link {
      display: block;
    }
    &__icon {
      margin-left: auto;
      margin-right: auto;
      width: 150px;
      height: 150px;
    }
    &__title {
      text-align: center;
      margin: 2rem 0;
    }
  }
`;

export default ResourceCatsList;