import React from 'react';
import Helmet from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';

const Meta = ({ pageTitle, seo, home, title }) => {
  const siteSettings = useStaticQuery(graphql`
    {
      sanitySiteSettings(_id: { eq: "siteSettings" }) {
        customCSS {
          code
        }
        title
      }
    }
  `);

  const {
    sanitySiteSettings: { customCSS, title: siteTitle },
  } = siteSettings;

  const titleTemplate = home ? siteTitle : `%s | ${siteTitle}`;

  // TODO: Use seo title if exists?

  return (
    <Helmet
      htmlAttributes={{
        lang: 'en',
      }}
      title={title}
      titleTemplate={titleTemplate}
    >
      <link rel="stylesheet" href="https://use.typekit.net/czd3yad.css" />
      <style type="text/css">{customCSS.code}</style>
      {seo && seo.metaDescription && (
        <meta name="description" content={seo.metaDescription} />
      )}
      <meta
        name="google-site-verification"
        content="8Hn-DGLgpC6y7EqjIZc7TJK1JzvsS5MendqOiNNipAY"
      />
    </Helmet>
  );
};

export default Meta;
