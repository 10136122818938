import React, { useContext, useState, useEffect } from 'react';
import styled from 'styled-components';
import { rgba } from 'polished';
import { useTransition, animated } from 'react-spring';
import { Link } from 'gatsby';
import sanityClient from '@sanity/client';

import SiteContext from '../SiteContext';
import { media } from '../theme';
import { NoType } from '../Resource/NoTypeResource';

const client = sanityClient({
  projectId: 'vfslawyp',
  dataset: 'production',
  // token: 'sanity-auth-token', // or leave blank to be anonymous user
  useCdn: true // `false` if you want to ensure fresh data
});

const SearchResults = () => {

  const { searchResults, searchActive, searchQuery } = useContext(SiteContext);
  // console.log(searchResults);

  const [resources, setResources] = useState([]);
  
  // console.log(resources);

  useEffect(() => {
    function fetchResources() {
      const resourceIds = searchResults.filter(({ type }) => type === 'resource');
      const query = `{${resourceIds
        .map((res, i) => `'${i}': *[_id == '${res._id}'][0] {
            id,
            title,
            slug,
            resourceType[0],
            resourceCategories[0]-> {
              slug
            },
            externalLink,
            document {
              asset->{
                url
              }
            },
          }`)
        .toString()}}`;
      client.fetch(query).then(res => {
        setResources(Object.values(res));
      });
    }
    fetchResources();
  }, [searchResults]);


  const categories = searchResults.filter(res => res.type === 'resourceCategory');

  const pages = searchResults.filter(res => res.type === 'page');
  const posts = searchResults.filter(res => res.type === 'post');
  
  // const searchOpen = searchActive || searchResults;

  const searchTransition = useTransition(searchActive || searchResults, null, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 }
  });

  return searchTransition.map(({ item, key, props }) => item && (
    <React.Fragment key={key}>
      <Backdrop style={props} />
      <ResultsList style={props}>
        {searchResults.length > 0 && (
          <h3>Search results for "{searchQuery}"</h3>
        )}
        {resources.length > 0 && (
          <h4>Resources</h4>
        )}
        {resources.map(({ id, title, resourceType, externalLink, resourceCategories, document, slug }) => {
          if (resourceType === 'externalLink') {
            return (
              <Result key={id}>
                <a
                  href={externalLink}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {title}
                </a>
              </Result>
            );
          }
          if (resourceType === 'content') {
            return <Result key={id}>
              <Link to={`/${resourceCategories.slug.current}`}>{title}</Link>
            </Result>
          }
          if(resourceType === 'document') {
            return (
              <Result key={id}>
                <a
                  href={document.asset.url}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {title}
                </a>
              </Result>
            );
          }
          if (resourceType === 'page') {
            return <Result key={id}>
              <Link to={`/resource/${slug.current}`}>{title}</Link>
            </Result>
          }
          return (
            <Result key={id}>
              <NoType>{title}</NoType>
            </Result>
          )
        })}
        {categories.length > 0 && (
          <h4>Categories</h4>
        )}
        {categories.map(({id, path, title}) => (
          <Result key={id}>
            <Link to={path}>{title}</Link>
          </Result>
        ))}
        {pages.length > 0 && (
          <h4>Page</h4>
        )}
        {pages.map(({ id, path, title }) => (
          <Result key={id}>
            <Link to={path}>{title}</Link>
          </Result>
        ))}
        {posts.length > 0 && (
          <h4>Posts</h4>
        )}
        {posts.map(({ id, path, title }) => (
          <Result key={id}>
            <Link to={path}>{title}</Link>
          </Result>
        ))}
        {searchActive && !searchQuery && (
          <Result>
            Start typing to see search results!
          </Result>
        )}
        {searchActive && searchQuery && searchResults.length < 1 && (
          <Result>
            No results for search query "{searchQuery}"
          </Result>
        )}
      </ResultsList>
    </React.Fragment>
  ));
};

const Result = styled.li`
  font-family: ${({ theme }) => theme.font.boldFamily};
  color: ${({ theme }) => theme.darkOrange};
  font-size: 1.8rem;
  
  border-bottom: 1px solid ${({ theme }) => theme.darkTeal};
  padding-bottom: 1rem;
  padding-top: 1.5rem;
  transition: .25s;
  :last-child {
    border-bottom: 0;
  }
  a {
    color: inherit;
    text-decoration: none;
    display: block;
  }
  :hover {
    background: ${({ theme }) => rgba(theme.dark, .25)};
  }
`;

const ResultsList = styled(animated.ul)`
  background: white;
  list-style: none;
  padding: 2rem 2rem 0;
  margin: 0;

  position: absolute;
  top: ${({ theme }) => theme.headerHeight}px;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  ${media.break`
    width: 50%;
  `}
  h4 {
    margin-top: 1rem;
    margin-bottom: 0;
  }
`;

const Backdrop = styled(animated.div)`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: ${({ theme }) => rgba(theme.dark, .75)};
  /* z-index: 5; */
`;

export default SearchResults;