import React from 'react';
import styled from 'styled-components';

const ExternalLink = ({ link, label }) => {
  return (
    <StyledItem className="menu-item external-link">
      <a className="menu-item__link" href={link} target="__blank" rel="nofollow noreferrer">
        {label}
      </a>
    </StyledItem>
  );
};

const StyledItem = styled.li`
  a, span {
    color: white;
    text-decoration: none;
    font-family: ${({ theme }) => theme.font.boldFamily};
    font-weight: 700;
    font-size: 2rem;
    cursor: pointer;
    &.sub-menu-label {
    display: block;
    text-align: center;
  }
  }
`;

export { StyledItem };
export default ExternalLink;