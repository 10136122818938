import React from 'react';
import styled from 'styled-components';
import { rgba, darken } from 'polished';
import classNames from 'classnames';

const NoTypeResource = ({ id, title, className }) => {
  return <NoType disabled className={classNames('resource', className)}>{title}</NoType>;
};

const NoType = styled.button`
  color: ${({ theme }) => rgba(darken(.07, theme.darkOrange), .7)};
  background: transparent;
  border: 0;
  padding: 0;
  font-family: inherit;
  font-weight: inherit;
  font-size: inherit;
  text-align: left;
`;

export { NoType };

export default NoTypeResource;