import React, { useState, useEffect } from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import { useViewport, getCurrentBreakpoint } from './utils';

const SiteContext = React.createContext();

const SiteContextProvider = ({ children }) => {

  const {
    allSanityResourceTag: tags,
    siteSearchIndex: {
      index: searchIndex
    }
  } = useStaticQuery(graphql`
    {
      allSanityResourceTag {
        edges {
          node {
            id
            title
          }
        }
      }
      siteSearchIndex {
        index
      }
    }
  `);

  const { viewport, ready } = useViewport();
  
  const [menuOpen, toggleMenu] = useState(false);

  const [searchQuery, setSearchQuery] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [searchActive, activateSearch] = useState(false);

  return (
    <SiteContext.Provider
      value={{
        viewport,
        ready,
        menuOpen,
        toggleMenu,
        breakpoint: getCurrentBreakpoint(viewport.width),
        tags,
        searchIndex,
        searchQuery,
        setSearchQuery,
        searchResults,
        setSearchResults,
        searchActive,
        activateSearch
      }}
    >
      {children}
    </SiteContext.Provider>
  );
}

export { SiteContextProvider };
export default SiteContext;