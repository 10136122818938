import React, { useContext } from 'react';
import styled from 'styled-components';

import SiteContext from '../SiteContext';
import media from '../media';

const NavToggler = () => {
  const { menuOpen, toggleMenu } = useContext(SiteContext);

  return (
    <StyledToggle className="nav-toggle" menuOpen={menuOpen} onClick={() => toggleMenu(!menuOpen)} buttonWidth={50} spanWidth={40}>
      <span />
      <span />
      <span />
    </StyledToggle>
  )
}

const StyledToggle = styled.button`
  ${media.break`
    display: none;
  `}
  border: 0;
  padding: 0;
  background: transparent;
  height: ${({ buttonWidth }) => buttonWidth}px;
  width: ${({ buttonWidth }) => buttonWidth}px;
  position: relative;
  :focus {
    outline: none;
  }
  span {
    position: absolute;
    background: white;
    height: 3px;
    width: ${({ spanWidth }) => spanWidth}px;
    top: 50%;
    left: ${({ buttonWidth, spanWidth }) =>  buttonWidth / 2 - spanWidth / 2}px;
    transition: 0.25s;
    &:nth-child(1) {
      top: ${({ menuOpen }) => menuOpen ? '50%' : '30%'};
      transform: ${({ menuOpen }) => menuOpen ? 'rotate(45deg)' : 'rotate(0)'};
    }
    &:nth-child(2) {
      opacity: ${({ menuOpen }) => menuOpen ? 0 : 1};
    }
    &:nth-child(3) {
      top: ${({ menuOpen }) => menuOpen ? '50%' : '70%'};
      transform: ${({ menuOpen }) => menuOpen ? 'rotate(-45deg)' : 'rotate(0)'};
    }
  }
`;

export default NavToggler;