import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`

  html {
    box-sizing: border-box;
    font-size: 10px;
  }

  *, *:before, *:after {
    box-sizing: inherit;
  }

  body {
    padding: 0;
    margin: 0;
    font-family: ${({ theme }) => theme.font.family};
    font-weight: 500;
    font-style: normal;
    font-size: 1.6rem;
    color: ${({ theme }) => theme.dark};
  }

  img {
    display: block;
    max-width: 100%;
    height: auto;
  }

  input, textarea, select, button {
    font-family: ${({ theme }) => theme.font.family};
  }

  input {
    font-family: ${({ theme }) => theme.font.family};
  }

  h1, h2, h3 {
    margin-bottom: 1rem;
    margin-top: 0;
    font-family: ${({ theme }) => theme.font.boldFamily};
    font-weight: 700;
    letter-spacing: 1px;
  }

  h1 {
    text-transform: uppercase;
    text-align: center;
    margin: 2rem 0;
  }

  p {
    font-weight: 300;
    margin-bottom: 1.5rem;
    margin-top: 0;
  }

  a {
    color: ${({ theme}) => theme.darkOrange};
  }

`;

export default GlobalStyle;
