import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useCountUp } from 'react-countup';
import { useStaticQuery, graphql } from 'gatsby';
import handleViewport from 'react-in-viewport';

import Content from './Content';
import { media } from './theme';

const HowToUse = () => {
  const {
    sanitySiteSettings: { _rawHowToUseContent },
    allSanityResource: { totalCount },
  } = useStaticQuery(graphql`
    {
      sanitySiteSettings(_id: { eq: "siteSettings" }) {
        _rawHowToUseContent
      }
      allSanityResource {
        totalCount
      }
    }
  `);

  return (
    <StyledHowToUse className="how-to-use">
      <div className="how-to-use__wrapper">
        <h2 className="how-to-use__heading">How To Use This Site</h2>
        <Content className="how-to-use__content">{_rawHowToUseContent}</Content>
      </div>
      <ResourceCount count={totalCount} />
    </StyledHowToUse>
  );
};

const ResourceCount = handleViewport(({ inViewport, forwardedRef, count }) => {
  const { countUp, start } = useCountUp({
    end: count,
  });

  const [counted, setCounted] = useState(false);

  useEffect(() => {
    if (inViewport && !counted) {
      start();
      setCounted(true);
    }
  }, [inViewport]);

  return (
    <StyledResourceCount className="resource-count" ref={forwardedRef}>
      <span className="count">{countUp}</span>
      <span className="resources-text">resources</span>
      <span className="and-counting">and counting!</span>
    </StyledResourceCount>
  );
});

const StyledResourceCount = styled.div`
  line-height: 1.25;
  font-family: futura-pt-condensed, sans-serif;
  ${media.break`
    flex: 0 0 250px;
  `}
  span {
    display: block;
    text-align: center;
  }
  .count {
    font-weight: 500;
    font-style: normal;
    color: ${({ theme }) => theme.blue};
    font-size: 15rem;
    line-height: 1;
  }
  .resources-text {
    font-weight: bold;
    font-size: 3.6rem;
    margin-bottom: 1rem;
    font-family: ${({ theme }) => theme.font.boldFamily};
  }
`;

const StyledHowToUse = styled.section`
  background: ${({ theme }) => theme.light};
  padding: 3rem 2rem;
  ${media.break`
    display: flex;
    padding: 5rem 4rem;
    justify-content: space-around;
    .how-to-use__wrapper {
      flex: 0 1 700px;
    }
    > div {
      padding: 1rem;
    }
  `}
  h2 {
    color: ${({ theme }) => theme.darkOrange};
    text-align: center;
    ${media.break`

    `}
  }
  .content-wrapper {
    margin-top: 2rem;
    margin-bottom: 2rem;
    line-height: 1.66;
    ${media.break`
      width: auto;
    `}
  }
`;

export default HowToUse;
