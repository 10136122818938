import React from 'react';
import styled from 'styled-components';
import { rgba } from 'polished';
import classNames from 'classnames';

const PageTitle = ({ headerImage, title, children, className }) => {
  return headerImage ? (
    <CategoryTitle
      className={classNames('page-title header-image', className)}
      bg={headerImage}
    >
      {title}{children}
    </CategoryTitle>
  ) : (
    <h1
      className={classNames('page-title no-header-image', className)}
    >
      {title}{children}
    </h1>
  );
};

const CategoryTitle = styled.h1`
  padding: 3rem 1rem;
  text-align: center;
  color: white;
  position: relative;
  background-color: ${({ theme }) => rgba(theme.blue, 0.7)};
  margin-top: 0;
  ::before {
    content: '';
    background-image: url('${({ bg }) => bg}');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
  }
`;

export default PageTitle;