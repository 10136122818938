import React, { useState } from 'react';
import styled from 'styled-components';
import { useTransition, animated } from 'react-spring';

import PageLink from './PageLink';
import ExternalLink from './ExternalLink';
import { gradient, media } from '../theme';
import { StyledItem } from './ExternalLink';

const SubMenu = ({ label, items }) => {

  const [subMenuOpen, setSubMenuOpen] = useState(false);

  const transition = useTransition(subMenuOpen, null, {
    from: {
      maxHeight: '0px',
      paddingTop: '0rem',
      paddingBottom: '0rem'
    },
    enter: {
      maxHeight: '500px',
      paddingTop: '1rem',
      paddingBottom: '1rem',
    },
    leave: {
      maxHeight: '0px',
      paddingTop: '0rem',
      paddingBottom: '0rem'
    }
  });

  return (
    <StyledItem
      style={{ position: 'relative' }}
      onMouseOver={() => setSubMenuOpen(true)}
      onMouseLeave={() => setSubMenuOpen(false)}
      className="menu-item has-sub-menu"
    >
      <span className="sub-menu-label">{label}</span>
      {transition.map(
        ({ item, key, props }) =>
          item && (
            <StyledSubMenu
              key={key}
              className="sub-menu"
              style={props}
            >
              {items.map(
                ({ label = '', pageLinkRef, _key, _type, link = '/' }) => {
                  if (_type === 'pageLink') {
                    return (
                      <PageLink key={_key} label={label} {...pageLinkRef} />
                    );
                  }
                  return <ExternalLink key={_key} link={link} label={label} />;
                }
              )}
            </StyledSubMenu>
          )
      )}
    </StyledItem>
  );
};

const StyledSubMenu = styled(animated.ul)`
  list-style: none;
  margin: 0;

  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 0;
  height: auto;
  overflow: hidden;

  ${media.break`
    padding: 1rem;
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    ${gradient}
    width: 200px;
  
  `}
  li {
    margin-bottom: 1rem;
    a {
      font-size: 1.6rem;
    }
  }
`;

export default SubMenu;