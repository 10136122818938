import React, { useRef, useContext } from 'react';
import styled from 'styled-components';
import { useSpring, animated } from 'react-spring';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faTimes } from '@fortawesome/free-solid-svg-icons';
import { Index } from 'elasticlunr';

import theme from '../theme';
import SiteContext from '../SiteContext';

const SearchForm = () => {

  const inputRef = useRef(null);

  const {
    searchQuery,
    searchIndex,
    setSearchQuery,
    setSearchResults,
    searchActive,
    activateSearch
  } = useContext(SiteContext);

  var index;
  
  const getOrCreateIndex = () => {
    return index
      ? index
      : // Create an elastic lunr index and hydrate with graphql query results
        Index.load(searchIndex);
  }

  const doSearch = e => {
    const query = e.target.value;
    index = getOrCreateIndex();
    setSearchQuery(query);
    // Query the index with search string to get an [] of IDs
    const results = index.search(query, {
      expand: true
    })
    // Map over each ID and return the full document
    .map(({ ref }) => index.documentStore.getDoc(ref));
    setSearchResults(results);
  }

  const handleFocus = () => {
    activateSearch(true);
  }

  const handleBlur = () => {
    if (!searchQuery) {
      activateSearch(false);
    }
  }

  const handleSubmit = e => {
    e.preventDefault();
  }

  const handleClose = () => {
    activateSearch(false);
    setSearchQuery('');
    setSearchResults([]);
  }

  const iconSpring = useSpring({
    left: inputRef.current ? (
      searchActive ? `${inputRef.current.clientWidth - 26}px` : '0px'
    ) : '0px',
  });

  return (
    <StyledForm className="search-form" onSubmit={handleSubmit} searchActive={searchActive}>
      <label className="search-form__label" htmlFor="search">
        <input
          className="search-form__input"
          type="text"
          value={searchQuery}
          onChange={doSearch}
          onFocus={handleFocus}
          onBlur={handleBlur}
          ref={inputRef}
          id="search"
        />
        <animated.div
          style={iconSpring}
          onClick={handleClose}
          className="search-form__icon-wrapper icon-wrapper"
        >
          <FontAwesomeIcon
            className="search-form__icon icon icon--search"
            icon={faSearch}
            color={theme.grey}
          />
          <FontAwesomeIcon
            className="search-form__icon icon icon--close"
            icon={faTimes}
            color={theme.grey}
          />
        </animated.div>
      </label>
    </StyledForm>
  );
}

const StyledForm = styled.form`
  flex: 0 1 200px;
  display: flex;
  align-items: center;
  padding: 3rem 1rem;
  justify-content: center;
  
  label {
    position: relative;
    .icon-wrapper {
      position: absolute;
      left: 0.5rem;
      padding: 0 .5rem;
      pointer-events: ${({ searchActive }) => searchActive? 'initial' : 'none'};
      top: 50%;
      transform: translateY(-50%);
      background: transparent;
      border: 0;
      width: 26px;
      height: 18px;
      z-index: 10;
      outline: none;
      .icon {
        transition: .25s;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        &--search {
          opacity: ${({ searchActive }) => searchActive ? 0 : 1};
        }
        &--close {
          opacity: ${({ searchActive }) => searchActive ? 1 : 0};
        }
      }
    }
  }
  input {
    box-shadow: ${({ theme }) => theme.shadow};
    border: none;
    border-image-width: 0;
    padding: 0.5rem;
    font-family: ${({ theme }) => theme.font.family};
  }
`;

export default SearchForm;