import React from 'react';
import { Link } from 'gatsby';

import { StyledItem } from './ExternalLink';

const MenuPage = ({ label, title, slug, _type }) => {
  return (
    <StyledItem className="menu-item menu-page">
      <Link className="menu-item__link" to="/news">
        {label || title}
      </Link>
    </StyledItem>
  );
};


export default MenuPage;