import React from 'react';
import styled, { ThemeProvider } from 'styled-components';

import theme from '../theme';
import Header from '../Header/Header';
import Meta from './Meta';
import GlobalStyle from './GlobalStyle';
import { SiteContextProvider } from '../SiteContext';
import ResourceCatsList from '../ResourceCatsList';
import Footer from './Footer';
import SearchResults from '../Search/SearchResults';
import HowToUse from '../HowToUse';

const Wrapper = ({ children, home = false, title, seoSettings }) => {
  return (
    <ThemeProvider theme={theme}>
      <SiteContextProvider>
        <PageWrapper>
          <Header home={home} />
          <div className="page-container">{children}</div>
          <ResourceCatsList />
          <HowToUse />
          <Footer />
          <SearchResults />
          <GlobalStyle />
          <Meta home={home} title={title} seo={seoSettings} />
        </PageWrapper>
      </SiteContextProvider>
    </ThemeProvider>
  );
};

const PageWrapper = styled.div`
  position: relative;
  ${({ theme }) =>
    theme.grid &&
    ` 
    display: grid;
    grid-template-rows: auto 1fr auto;
    grid-template-columns: 100%;
    min-height: 100vh;
  `}
`;

export default Wrapper;
