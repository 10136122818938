import React from 'react';
import { Link } from 'gatsby';

import { StyledItem } from './ExternalLink';

const PageLink = ({ label, title, slug, _type }) => {
  return (
    <StyledItem className="menu-item page-link">
      <Link className="menu-item__link" to={`/${_type === 'homePage' ? '' : slug.current}`}>
        {label || title}
      </Link>
    </StyledItem>
  );
};


export default PageLink;