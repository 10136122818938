import React, { useContext } from 'react';
import styled from 'styled-components';
import { useStaticQuery, graphql } from 'gatsby';

import { media, gradient } from '../theme';
import Nav from './Nav';
import NavLogo from './NavLogo';
import NavToggler from './NavToggler';
import SiteContext from '../SiteContext'; 

const Header = ({ home }) => {

  const { breakpoint } = useContext(SiteContext);

  const {
    sanitySiteSettings: { menu }
  } = useStaticQuery(graphql`
    query NavMenuQuery {
      sanitySiteSettings(_id: { eq: "siteSettings" }) {
        menu {
          ... on SanityExternalLink {
            _key
            _type
            label
            link
          }
          ... on SanityPageLink {
            _key
            _type
            label
            pageLinkRef {
              ... on SanityHomePage {
                id
                _type
              }
              ... on SanityPage {
                id
                _type
                title
                slug {
                  current
                }
              }
              ... on SanityResourceCategory {
                id
                _type
                title
                slug {
                  current
                }
              }
            }
          }
          ... on SanitySubMenu {
            _key
            _type
            subMenuItems {
              ... on SanityExternalLink {
                _key
                _type
                label
                link
              }
              ... on SanityPageLink {
                _key
                _type
                label
                pageLinkRef {
                  ... on SanityPage {
                    id
                    title
                    slug {
                      current
                    }
                    _type
                  }
                  ... on SanityResourceCategory {
                    id
                    _type
                    slug {
                      current
                    }
                    title
                  }
                }
              }
            }
            topLevelLabel
          }
          ... on SanityNewsPage {
            _key
            _type
            label
          }
        }
      }
    }
  `);
  
  return (
    <StyledHeader className="header">
      {/* Don't show NavLogo on the homepage unless we're on mobile */}
      {(!home || breakpoint === 'small') && <NavLogo />}
      {/* Contains the nav menu and search form */}
      <Nav menu={menu} />
      <NavToggler />
    </StyledHeader>
  );
}

const StyledHeader = styled.header`
  height: ${({ theme }) => theme.headerHeight}px;
  ${gradient}
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2rem;
  position: relative;
  z-index: 1;
  ${media.break`
  `}
`;




export default Header;