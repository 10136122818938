import React from 'react';

import PageLink from './PageLink';
import SubMenu from './SubMenu';
import ExternalLink from './ExternalLink';
import MenuPage from './MenuPage';

const Menu = ({ menu }) => {

  return (
    <ul className="header-nav__menu">
      {menu.map(
        ({
          _key,
          _type,
          label = '',
          pageLinkRef,
          link = '/',
          topLevelLabel,
          subMenuItems
        }) => {
          if (_type === 'pageLink') {
            return <PageLink key={_key} {...pageLinkRef} label={label} />;
          }
          if (_type === 'subMenu') {
            return (
              <SubMenu key={_key} items={subMenuItems} label={topLevelLabel} />
            );
          }
          if (_type === 'newsPage') {
            return (
              <MenuPage key={_key} label={label} />
            );
          }
          return <ExternalLink key={_key} link={link} label={label} />;
        }
      )}
    </ul>
  );
};

export default Menu;